<template>
  <div class="home" >
    <van-nav-bar
      title="市场行情"
      placeholder
      fixed
      :border="false"
    >
<!--      <template #right v-if="userinfo">
        &lt;!&ndash;            <div class="flex">&ndash;&gt;
        <van-icon name="bell" color="#f5f5f5" size="16"/>
        <div style="color: #F6D0AB; font-size: 12px; margin-left: -10px" @click="quotationShowHandle">到价提醒</div>
        &lt;!&ndash;            </div>&ndash;&gt;
      </template>-->
    </van-nav-bar>
    <!-- <header class="head">市场行情</header> -->
    <!-- 时间栏 -->
    <!--    <div class="quote-divider"></div>-->
    <div class="back">
      <van-sticky>
        <div class="timeleft">

          <!-- <div @dblclick="quoted()" @click="Buygold()">{{ timecenter }}</div> -->
          <div>{{ timecenter }}</div>
          <div class="timeright" :style="{color:opentrade == true && timeTrade?'#00AD8C':'#F52621'}">
            {{ opentrade == true && timeTrade ? "开盘中" : "已停盘" }}
          </div>
        </div>
      </van-sticky>
    </div>

    <van-notice-bar
      text="费用：金条提货实时工费5元每克 金条回购提纯费用5元每克"
      :left-icon="require('@/assets/images/icon_notice@2x.png')"
      background="#FFFFFF"
      color="#39394D"
      speed="60"
    />

    <!-- 主体 -->
    <van-pull-refresh
      v-model="isLoading"
      success-text="刷新成功"
      @refresh="onRefresh"
      style="min-height: 100vh; background-color: #F1F3F7"
    >
      <div class="totalwidth" v-if="havedata">

        <!-- 现货价格 -->
        <div class="productitems">
          <div class="header">
            <span class="headerBg priceBg"></span>
            <span>现货价格</span> </div>
          <ul class="flex ulflex topul">
            <!-- <li class="firstbox">&nbsp;</li> -->
            <li style="flex: 2">商品</li>
            <li class="navleft">回购</li>
            <li class="navright">销售</li>
            <li>高/低</li>
          </ul>

          <div
            v-for="(items, index) in arrcenter.spotPrice"
            :key="items.engname"
            class="ulcenter"
          >
            <!-- <div
              :class="arrcenter.huangjin.length % 2 == 0 ? 'oddli' : ''"
              v-if="index == 1"
              class="firstbox"
            >
              黄金
            </div> -->
            <ul
              class="flex ulflex productlist" v-if="index === 0 || index === 1"
              :class="
              index === 1
                ? 'lastul'
                : index === 0
                ? 'firstul'
                : ''
            "
            >
              <!-- 加了注释（以下的位置都一样） -->
              <li class="flex_product">{{ items.engname }}</li>
              <template v-if="sendmsg">
                <li
                  :class="
                  // new_tuisong_val_before['huangjin'].prices[items['engname']][
                  //   'huigou'
                  // ] <= items.productData['huigou']
                  //   ? 'redfont'
                  //   : 'greenfont'
                  items.productData['huigouColor']
                "
                >

                  <template v-if="items.productData['hgDisplay'] == 'SHOW'">
                    {{ items.productData["huigou"] | changeTwoDecimal_x }}

                  </template>
                  <template v-else> - </template>
                </li>
                <li
                  :class="
                  // new_tuisong_val_before['huangjin'].prices[items['engname']][
                  //   'xiaoshou'
                  // ] <= items.productData['xiaoshou']
                  //   ? 'redfont'
                  //   : 'greenfont'
                  items.productData['huigouColor']
                "
                >
                  <template v-if="items.productData['xsDisplay'] == 'SHOW'">
                    {{ items.productData["xiaoshou"] | changeTwoDecimal_x}}
                  </template>
                  <template v-else> - </template>
                </li>
                <li class="lastli">
                  <div
                    :class="
                    // new_tuisong_val_before['huangjin'].prices[items['engname']][
                    //   'high'
                    // ] <= items.productData['high']
                    //   ? 'redfont'
                    //   : 'greenfont'
                    items.productData['huigouColor']
                  "
                  >
                    {{ items.productData["high"] | changeTwoDecimal_x }}
                  </div>
                  <div
                    :class="
                    // new_tuisong_val_before['huangjin'].prices[items['engname']][
                    //   'low'
                    // ] <= items.productData['low']
                    //   ? 'redfont'
                    //   : 'greenfont'
                    items.productData['huigouColor']
                  "
                  >
                    {{ items.productData["low"] | changeTwoDecimal_x }}
                  </div>
                </li>
              </template>
              <template v-else>
                <li class="redfont">
                  <template v-if="items.productData['hgDisplay'] == 'SHOW'">
                    <div>
                      {{ items.productData["huigou"] | changeTwoDecimal_x }}
                    </div>
                  </template>
                  <template v-else> - </template>
                  <!-- {{items.productData['huigou'] | changeTwoDecimal_f}} -->
                </li>
                <li class="redfont">
                  <template v-if="items.productData['xsDisplay'] == 'SHOW'">
                    {{ items.productData["xiaoshou"] | changeTwoDecimal_x }}
                  </template>
                  <template v-else> - </template>
                  <!-- {{items.productData['xiaoshou'] | changeTwoDecimal_f}} -->
                </li>
                <li class="lastli">
                  <div>{{ items.productData["high"] | changeTwoDecimal_x }}</div>
                  <div>{{ items.productData["low"] | changeTwoDecimal_x }}</div>
                </li>
              </template>
            </ul>
          </div>
        </div>

        <!-- 国内行情 -->
        <div class="productitems">
          <div class="header">
            <span class="headerBg priceBg"></span>
            <span>国内行情</span> </div>
          <!-- -------------------- -->
          <ul class="flex ulflex topul">
            <!-- <li class="firstbox">&nbsp;</li> -->
            <li style="flex: 2">商品</li>
            <li class="navleft">回购</li>
            <li class="navright">销售</li>
            <li>高/低</li>
          </ul>
          <!-- -------------------- -->
          <div
            v-for="(items, index) in arrcenter.shangjinsuo"
            :key="items.productData['name']"
            class="ulcenter"
          >
            <!-- <div
              :class="arrcenter.huangjin.length % 2 == 0 ? 'oddli' : ''"
              v-if="index == 1"
              class="firstbox"
            >
              上金所
            </div> -->
            <ul
              class="flex ulflex productlist"
              :class="
              index == arrcenter.shangjinsuo.length - 1
                ? 'lastul'
                : index == 0
                ? 'firstul'
                : ''
            "
              v-if="items.productData['display'] == 'SHOW'"
            >
              <!-- <li class="firstbox"> -->
              <!-- {{index+1 == (arrcenter.shangjinsuo.length/2).toFixed()?'上金所':''}} -->
              <!-- </li> -->

              <li class="flex_product">{{ items.productData["name"] =='黄金99.99'?'黄金9999':items.productData["name"] =='黄金99.95'?'黄金9995': items.productData["name"]}}</li>
              <template v-if="sendmsg">
                <li
                  :class="
                  // new_tuisong_val_before['sge'].prices[items['engname']][
                  //   'huigou'
                  // ] <= items.productData['huigou']
                  //   ? 'redfont'
                  //   : 'greenfont'
                  items.productData['huigouColor']
                "
                >
                  <!-- {{items.productData['huigou'] | changeTwoDecimal_f}} -->
                  <template v-if="items.productData['hgDisplay'] == 'SHOW'">
                    <div v-if="items.productData['name'] == '白银(T+D)'">
                      {{ items.productData["huigou"] }}
                    </div>
                    <div v-else>
                      {{ items.productData["huigou"] | changeTwoDecimal_f }}
                    </div>
                    <!-- <template v-if="items.productData['name'] == '白银(T+D)'">
                  </template>
                  <template v-else> -->
                    <!-- {{items.productData['huigou'] | changeTwoDecimal_f}}       -->
                    <!-- </template> -->
                  </template>
                  <template v-else> - </template>
                </li>
                <li
                  :class="
                  // new_tuisong_val_before['sge'].prices[items['engname']][
                  //   'xiaoshou'
                  // ] <= items.productData['xiaoshou']
                  //   ? 'redfont'
                  //   : 'greenfont'
                  items.productData['huigouColor']
                "
                >
                  <template v-if="items.productData['xsDisplay'] == 'SHOW'">
                    <!-- {{items.productData['xiaoshou'] | changeTwoDecimal_f}}       -->
                    <div v-if="items.productData['name'] == '白银(T+D)'">
                      {{ items.productData["xiaoshou"] }}
                    </div>
                    <div v-else>
                      {{ items.productData["xiaoshou"] | changeTwoDecimal_f }}
                    </div>
                  </template>
                  <template v-else> - </template>
                </li>
                <li class="lastli">
                  <template v-if="items.productData['name'] == '白银(T+D)'">
                    <div
                      :class="
                      // new_tuisong_val_before['sge'].prices[items['engname']][
                      //   'high'
                      // ] <= items.productData['high']
                      //   ? 'redfont'
                      //   : 'greenfont'
                      items.productData['huigouColor']
                    "
                    >
                      {{ items.productData["high"] }}
                    </div>
                    <div
                      :class="
                      // new_tuisong_val_before['sge'].prices[items['engname']][
                      //   'low'
                      // ] <= items.productData['low']
                      //   ? 'redfont'
                      //   : 'greenfont'
                      items.productData['huigouColor']
                    "
                    >
                      {{ items.productData["low"] }}
                    </div>
                  </template>
                  <template v-else>
                    <div
                      :class="
                      // new_tuisong_val_before['sge'].prices[items['engname']][
                      //   'high'
                      // ] <= items.productData['high']
                      //   ? 'redfont'
                      //   : 'greenfont'
                      items.productData['huigouColor']
                    "
                    >
                      {{ items.productData["high"] | changeTwoDecimal_f }}
                    </div>
                    <div
                      :class="
                      // new_tuisong_val_before['sge'].prices[items['engname']][
                      //   'low'
                      // ] <= items.productData['low']
                      //   ? 'redfont'
                      //   : 'greenfont'
                      items.productData['huigouColor']
                    "
                    >
                      {{ items.productData["low"] | changeTwoDecimal_f }}
                    </div>
                  </template>
                </li>
              </template>
              <template v-else>
                <li class="redfont">
                  <template v-if="items.productData['hgDisplay'] == 'SHOW'">
                    <!-- {{items.productData['huigou'] | changeTwoDecimal_f}}     -->
                    <div v-if="items.productData['name'] == '白银(T+D)'">
                      {{ items.productData["huigou"] }}
                    </div>
                    <div v-else>
                      {{ items.productData["huigou"] | changeTwoDecimal_f }}
                    </div>
                  </template>
                  <template v-else> - </template>
                  <!-- {{items.productData['huigou'] | changeTwoDecimal_f}} -->
                </li>
                <li class="redfont">
                  <!-- {{items.productData['xiaoshou'] | changeTwoDecimal_f}} -->
                  <template v-if="items.productData['xsDisplay'] == 'SHOW'">
                    <!-- {{items.productData['xiaoshou'] | changeTwoDecimal_f}}       -->
                    <div v-if="items.productData['name'] == '白银(T+D)'">
                      {{ items.productData["xiaoshou"] }}
                    </div>
                    <div v-else>
                      {{ items.productData["xiaoshou"] | changeTwoDecimal_f }}
                    </div>
                  </template>
                  <template v-else> - </template>
                </li>
                <li class="lastli">
                  <template v-if="items.productData['name'] == '白银(T+D)'">
                    <div>{{ items.productData["high"] }}</div>
                    <div>{{ items.productData["low"] }}</div>
                  </template>
                  <template v-else>
                    <div>
                      {{ items.productData["high"] | changeTwoDecimal_f }}
                    </div>
                    <div>{{ items.productData["low"] | changeTwoDecimal_f }}</div>
                  </template>
                </li>
              </template>
            </ul>
          </div>
        </div>


        <!-- 国际行情 -->
        <div class="productitems">
          <div class="header">
            <span class="headerBg internationalBg"></span>
            <span>国际行情</span> </div>
          <!-- ---------------- -->
          <ul class="flex ulflex topul">
            <!-- <li class="firstbox">&nbsp;</li> -->
            <li style="flex: 2">商品</li>
            <li class="navleft">回购</li>
            <li class="navright">销售</li>
            <li>高/低</li>
          </ul>
          <!-- ---------------- -->
          <div
            v-for="(items, index) in arrcenter.guojixianhuo"
            :key="items.productData['name']"
            class="ulcenter"
          >
            <ul
              class="flex ulflex productlist"
              :class="
              index == arrcenter.guojixianhuo.length - 1
                ? 'lastul'
                : index == 0
                ? 'firstul'
                : ''
            "
              v-if="items.productData['display'] == 'SHOW'"
            >
              <!-- <li
                class="firstbox"
                :class="arrcenter.guojixianhuo.length % 2 == 0 ? 'oddli' : ''"
              >
                {{
                  index + 1 == (arrcenter.guojixianhuo.length / 2).toFixed()
                    ? "现货"
                    : ""
                }}
              </li> -->
              <li class="flex_product">{{ items.productData["name"] }}</li>
              <template v-if="sendmsg">
                <li
                  :class="
                  // new_tuisong_val_before['international'].prices[
                  //   items['engname']
                  // ]['huigou'] <= items.productData['huigou']
                  //   ? 'redfont'
                  //   : 'greenfont'
                  items.productData['huigouColor']
                "
                >
                  <template
                    v-if="
                    items.productData['name'] == '伦敦金' ||
                      items.productData['name'] == '伦敦钯' ||
                      items.productData['name'] == '伦敦铂'
                  "
                  >
                    {{ items.productData["huigou"] | changeTwoDecimal_f }}
                  </template>
                  <template
                    v-else-if="
                    items.productData['name'] == '伦敦银' ||
                      items.productData['name'] == '美元指数'
                  "
                  >
                    {{ items.productData["huigou"] | changeTwoDecimal_fthree }}
                  </template>
                  <template v-else>
                    {{ items.productData["huigou"] }}
                  </template>
                </li>
                <li
                  :class="
                  // new_tuisong_val_before['international'].prices[
                  //   items['engname']
                  // ]['xiaoshou'] <= items.productData['xiaoshou']
                  //   ? 'redfont'
                  //   : 'greenfont'
                  items.productData['huigouColor']
                "
                >
                  <template
                    v-if="
                    items.productData['name'] == '伦敦金' ||
                      items.productData['name'] == '伦敦钯' ||
                      items.productData['name'] == '伦敦铂'
                  "
                  >
                    {{ items.productData["xiaoshou"] | changeTwoDecimal_f }}
                  </template>
                  <template
                    v-else-if="
                    items.productData['name'] == '伦敦银' ||
                      items.productData['name'] == '美元指数'
                  "
                  >
                    {{ items.productData["xiaoshou"] | changeTwoDecimal_fthree }}
                  </template>
                  <template v-else>
                    {{ items.productData["xiaoshou"] }}
                  </template>
                </li>
                <li class="lastli">
                  <div
                    :class="
                    // new_tuisong_val_before['international'].prices[
                    //   items['engname']
                    // ]['high'] <= items.productData['high']
                    //   ? 'redfont'
                    //   : 'greenfont'
                    items.productData['huigouColor']
                  "
                  >
                    <template
                      v-if="
                      items.productData['name'] == '伦敦金' ||
                        items.productData['name'] == '伦敦钯' ||
                        items.productData['name'] == '伦敦铂'
                    "
                    >
                      {{ items.productData["high"] | changeTwoDecimal_f }}
                    </template>
                    <template
                      v-else-if="
                      items.productData['name'] == '伦敦银' ||
                        items.productData['name'] == '美元指数'
                    "
                    >
                      {{ items.productData["high"] | changeTwoDecimal_fthree }}
                    </template>
                    <template v-else>
                      {{ items.productData["high"] }}
                    </template>
                  </div>
                  <div
                    :class="
                    // new_tuisong_val_before['international'].prices[
                    //   items['engname']
                    // ]['low'] <= items.productData['low']
                    //   ? 'redfont'
                    //   : 'greenfont'
                    items.productData['huigouColor']
                  "
                  >
                    <template
                      v-if="
                      items.productData['name'] == '伦敦金' ||
                        items.productData['name'] == '伦敦钯' ||
                        items.productData['name'] == '伦敦铂'
                    "
                    >
                      {{ items.productData["low"] | changeTwoDecimal_f }}
                    </template>
                    <template
                      v-else-if="
                      items.productData['name'] == '伦敦银' ||
                        items.productData['name'] == '美元指数'
                    "
                    >
                      {{ items.productData["low"] | changeTwoDecimal_fthree }}
                    </template>
                    <template v-else>
                      {{ items.productData["low"] }}
                    </template>
                  </div>
                </li>
              </template>

              <template v-else>
                <li class="redfont">
                  <template
                    v-if="
                    items.productData['name'] == '伦敦金' ||
                      items.productData['name'] == '伦敦钯' ||
                      items.productData['name'] == '伦敦铂'
                  "
                  >
                    {{ items.productData["huigou"] | changeTwoDecimal_f }}
                  </template>
                  <template
                    v-else-if="
                    items.productData['name'] == '伦敦银' ||
                      items.productData['name'] == '美元指数'
                  "
                  >
                    {{ items.productData["huigou"] | changeTwoDecimal_fthree }}
                  </template>
                  <template v-else>
                    {{ items.productData["huigou"] }}
                  </template>
                  <!-- {{items.productData['huigou']}} -->
                </li>
                <li class="redfont">
                  {{ items.productData["xiaoshou"] }}
                </li>
                <li class="lastli">
                  <div>{{ items.productData["high"] }}</div>
                  <div>{{ items.productData["low"] }}</div>
                </li>
              </template>
            </ul>
          </div>
        </div>
<!--        <div class="beian">
          <a target="_blank" href="https://beian.miit.gov.cn/">粤ICP备2022139244号-1</a>
        </div>-->
      </div>
    </van-pull-refresh>
    <!-- 弹出通知 -->
    <van-popup v-model="showNotice" @close="noticeClose" close-icon="close" style="background: transparent; width: 350px">
      <img :src="noticeImg" alt="">
      <div style="text-align:center">
        <img src="@/assets/images/close_notice.png" alt="" style="height: 40px; width: 40px" @click="noticeClose">
      </div>
    </van-popup>
    <!-- 弹出价格提醒 -->
    <van-popup v-model="quotationShow" position="bottom" close-icon-position="top-left" closeable round>
      <div class="quotationTitle">
        到价提醒
      </div>
      <div class="quotationForm">
        <div class="flex quotationFormItem">
          <div class="titleinfo">订阅商品</div>
          <div class="flex_between" @click="categoryShow = true">
            <div class="titlevalue">
              {{ quotationForm.key === 'bojin9996' ? '铂金' : quotationForm.key === 'bajin9996' ? '钯金' : quotationForm.key === 'baiyin' ? '白银' : '黄金'}}
            </div>
            <div><i class="el-icon-arrow-right"></i></div>
          </div>
        </div>
        <div class="flex quotationFormItem">
          <div class="titleinfo">当前价格</div>
          <div class="titlevalue">
            {{ quotationForm.nowPrice }}
          </div>
        </div>
        <div class="flex quotationFormItem">
          <div class="titleinfo">订阅方向</div>
          <van-radio-group v-model="quotationForm.type" direction="horizontal" @change="quotationFormTypeChange">
            <van-radio name="HUIGOU" icon-size="16" checked-color="red" >回购</van-radio>
            <van-radio name="XIAOSHOU" icon-size="16" checked-color="red">销售</van-radio>
          </van-radio-group>
        </div>
        <div class="flex quotationFormItem">
          <div class="titleinfo">订阅价格</div>
          <div class="titlevalue flex">
            <div>￥</div>
            <div>
              <el-input placeholder="请输入提醒价格" v-model="quotationForm.price"></el-input>
            </div>
          </div>
        </div>
      </div>
      <!--      <div class="quotationDescribe">-->
      <!--        {{ quotationForm.type === "HUIGOU" ? "高于" : "低于" }}设置报价你将收到微信通知-->
      <!--      </div>-->
      <el-button class="quotationBtn" :disabled="quotationForm.price ? false : true" @click="quotationCommit">设置提醒</el-button>
    </van-popup>
    <van-action-sheet
      v-model="categoryShow"
      :actions="category"
      cancel-text="取消"
      close-on-click-action
      @select="confirmCategory"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import Stomp from "stompjs";
import router from "../router/index";
import quoted from "./quoted";
import * as api from "@/utils/api";
import axios from "axios";
// import { getStyle } from 'node_modules/highcharts/highcharts';
export default {
  name: "Home",
  data() {
    return {
      // 下拉刷新
      showNotice: false,
      noticeImg: null,
      isLoading:false,
      quotationShow: false,
      quotationForm: {
        key: "huangjin9999",
        type: "HUIGOU",
        price: "",
        nowPrice: "",
        operator: "EQ",
        alertType: ["WEIXIN_GONGZHONGHAO"],
        openId: "",
        scene: 1000
      },
      category: [{name:'黄金',id:'0', key: 'huangjin9999'},{name:'铂金',id:'1', key: 'bojin9996'}],
      categoryShow: false,
      quotationAction: "",
      userinfo: "",
      year: "",
      mouth: Number,
      day: Number,
      sendmsg: false,
      onedata: [],
      // 旧值判断
      isfirstval: false,
      // 克隆后获取之前的值做比较
      lastarr: [],
      // 深度克隆后的赋值
      updataarr: [],
      firstload: true,
      wsPrice: null,
      prePrice: {},
      isload: true,
      arrcenter: {
        huangjin: [],
        bojin: [],
        bajin: [],
        baiyin: [],
        shangjinsuo: [],
        guojixianhuo: [],
        // 现货价格
        spotPrice:[]
      },
      newarrlist: [],
      // 是否开盘中
      opentrade: false,
      havedata: false,
      timecenter: "",
      timeTrade: false,
      client: {},
      productall: {
        huangjin: [],
        bojin: [],
        bajin: [],
        baiyin: [],
        shangjinsuo: [],
        guojixianhuo: []
      },
      new_tuisong_val_last: {},
      new_tuisong_val_before: {},

      Compareval: {
        huangjin: [],
        bojin: [],
        bajin: [],
        baiyin: [],
        shangjinsuo: [],
        guojixianhuo: []
      },
      appId: "wxf3ab293ae093d4ca",
      templateId: 'TmbNMQDfZVc57W4kZWu98WjGMl5NnptSy6NOW5AftGc',
    };
  },
  activated() {
    this.userinfo = JSON.parse( localStorage.getItem('userinfo'))
  },
  mounted() {
    console.log(this.$route.query)
    if(this.$route.query.referrerCode) {
      localStorage.setItem('referrerCode', this.$route.query.referrerCode)
    }
    if(this.$route.query.code){
      // 微信获取openIdAPI
      api.getWeixinOpenId(this.$route.query.code).then(res => {
        console.log(res)
        if (!res.openId) {
          this.$toast.fail("微信登录失败");
        } else {
          localStorage.setItem('openId', res.openId)
          this.quotationShowHandle()
        }
      })
    }
    if (this.$route.query.action === 'confirm'){
      // 客户同意
      this.quotationAction = this.$route.query.action
      this.quotationShowHandle()
    } else if (this.$route.query.action === 'cancel') {
      this.quotationAction = this.$route.query.action
      this.$toast.fail("到价提醒功能需确认接收公众号消息推送");
    }
    const that = this;
    this.connect();
    api.getAboutPhoto().then(res => {
      res.forEach(item => {
        if (item.eventType === "SPLASH") {
          that.noticeImg = item.img;
        }
      });
      const noticeParams = JSON.parse(localStorage.getItem("noticeParam1"));
      if (!that.noticeImg) {
        that.showNotice = false;
      } else if (!noticeParams) {
        that.showNotice = true;
      } else {
        const noticeTime = noticeParams.noticeTime;
        const noticeImg = noticeParams.noticeImg;
        console.log("noticeParams: ", noticeParams);
        const nowTime = new Date().getTime();
        const differenceTime = Math.floor(
          (nowTime - noticeTime) / (24 * 3600 * 1000)
        );
        that.showNotice = that.noticeImg !== noticeImg || differenceTime > 1;
      }
    });
    setInterval(() => {
      const t = that.onedata["time"];
      const s = new Date().getTime()
      let date = new Date(Number(t) * 1000);
      if (isNaN(t)) {
        date = new Date();
      }

      this.timebox(date);
    },500);
  },
  computed: {},
  watch: {
    onedata: function(newN, oldN) {
      const than = this;

      // console.log(newN);
      Object.entries(newN).forEach(([typeKey, type]) => {
        if (["time", "trade", "id"].includes(typeKey)) {
          return;
        }
        Object.entries(type.prices).forEach(([goodsKey, goods]) => {
          //  debugger
          let huigouColor = "redfont";
          if (oldN && !Array.isArray(oldN)) {
            const oldGoods = oldN[typeKey].prices[goodsKey];
            const oldHuigou = oldGoods["huigou"];
            const newHuigou = goods["huigou"];
            const oldHuigouColor = oldGoods["huigouColor"];
            // console.log(newHuigou, oldHuigou);
            // console.log(oldHuigouColor);
            // debugger
            huigouColor =
              newHuigou < oldHuigou
                ? "greenfont"
                : newHuigou == oldHuigou
                  ? oldHuigouColor
                  : "redfont";
          }
          goods["huigouColor"] = huigouColor;
          // debugger
          // console.log(huigouColor);
        });
      });
      if (oldN instanceof Array) {
      } else {
        this.new_tuisong_val_before = JSON.parse(JSON.stringify(oldN));
        this.new_tuisong_val_last = JSON.parse(JSON.stringify(newN));
        this.sendmsg = true;
        this.newlistsort(this.new_tuisong_val_last);
      }
    }
  },
  filters: {
    changeTwoDecimal_f(x) {
      var f_x = parseFloat(x);
      if (isNaN(f_x)) {
        return 0;
      }
      // Math.round
      var f_x = (x * 100) / 100;
      let s_x = f_x.toString();
      let pos_decimal = s_x.indexOf(".");
      if (pos_decimal < 0) {
        pos_decimal = s_x.length;
        s_x += ".";
      }
      while (s_x.length <= pos_decimal + 2) {
        s_x += "0";
      }
      return s_x;

    },
    changeTwoDecimal_x(x) {
      var f_x = parseFloat(x);
      if (isNaN(f_x)) {
        return 0;
      }
      // Math.round
      var f_x = (x * 100) / 100;
      let s_x = f_x.toString();
      let pos_decimal = s_x.indexOf(".");
      if (pos_decimal < 0) {
        pos_decimal = s_x.length;
        s_x += ".";
      }
      while (s_x.length <= pos_decimal + 2) {
        s_x += "0";
      }

      if (s_x.length >= 7) {
        // console.log('sx',s_x,'x',Number(s_x).toFixed(3));

        return Number(s_x).toFixed(3);
      }

      return s_x;

    },
    changeTwoDecimal_fthree(x) {
      var f_x = parseFloat(x);
      if (isNaN(f_x)) {
        return 0;
      }
      // Math.round
      var f_x = (x * 100) / 100;
      let s_x = f_x.toString();
      let pos_decimal = s_x.indexOf(".");
      if (pos_decimal < 0) {
        pos_decimal = s_x.length;
        s_x += ".";
      }
      while (s_x.length <= pos_decimal + 3) {
        s_x += "0";
      }
      if (s_x.length >= 7) {
        return Number(s_x).toFixed(3);
      }
      return s_x;
    }
  },
  methods: {
    onRefresh(){

      this.connect()
      this.isLoading = false;
    },
    banklist(){
      this.$router.push('/bank_list')
    },
    // Buygold(){
    //   this.$router.push('/Buy_gold')
    // },
    quoted(){
      this.$router.push('/quoted')
    },
    newlistsort(data) {
      const than = this;
      than.arrcenter.huangjin = [];
      than.arrcenter.bojin = [];
      than.arrcenter.bajin = [];
      than.arrcenter.baiyin = [];
      than.arrcenter.guojixianhuo = [];
      than.arrcenter.shangjinsuo = [];
      than.arrcenter.spotPrice =[];
      than.arrcenter.hujinPrice =[];
      than.arrcenter.cihujinPrice =[];
      than.productall.huangjin = [];
      than.productall.bojin = [];
      than.productall.bajin = [];
      than.productall.baiyin = [];
      than.productall.shangjinsuo = [];
      const resbody = data;
      for (const i in resbody) {
        if (resbody[i].name === "黄金") {
          for (const b in resbody[i].prices) {
            if (resbody[i].prices[b]["display"] == "SHOW") {
              this.arrcenter.huangjin.push({
                engname: b,
                sort: resbody[i].prices[b].sort,
                productData: resbody[i].prices[b]
              });

            }
          }

          this.arrcenter.huangjin.sort(this.compare("sort"));
          // this.productall.huangjin = resbody[i].prices
        }
        if (resbody[i].name === "铂金") {
          for (const b in resbody[i].prices) {
            if (resbody[i].prices[b]["display"] == "SHOW") {
              this.arrcenter.bojin.push({
                engname: b,
                sort: resbody[i].prices[b].sort,
                productData: resbody[i].prices[b]
              });
            }
          }
          this.arrcenter.bojin.sort(this.compare("sort"));
        }
        if (resbody[i].name === "钯金") {
          for (const b in resbody[i].prices) {
            if (resbody[i].prices[b]["display"] == "SHOW") {
              this.arrcenter.bajin.push({
                engname: b,
                sort: resbody[i].prices[b].sort,
                productData: resbody[i].prices[b]
              });
            }
          }
          this.arrcenter.bajin.sort(this.compare("sort"));
        }
        if (resbody[i].name === "白银") {
          for (const b in resbody[i].prices) {
            if (resbody[i].prices[b]["display"] == "SHOW") {
              this.arrcenter.baiyin.push({
                engname: b,
                sort: resbody[i].prices[b].sort,
                productData: resbody[i].prices[b],
                time: resbody.time
              });
            }
          }
          this.arrcenter.baiyin.sort(this.compare("sort"));
        }
        if (resbody[i].name === "上金所") {
          for (const b in resbody[i].prices) {
            if (resbody[i].prices[b]["display"] == "SHOW") {
              this.arrcenter.shangjinsuo.push({
                engname: b,
                sort: resbody[i].prices[b].sort,
                productData: resbody[i].prices[b]
              });
            }
          }

          this.arrcenter.shangjinsuo.sort(this.compare("sort"));
        }
        if (resbody[i].name === "国际现货") {
          for (const b in resbody[i].prices) {
            if (resbody[i].prices[b]["display"] == "SHOW") {
              this.arrcenter.guojixianhuo.push({
                engname: b,
                sort: resbody[i].prices[b].sort,
                productData: resbody[i].prices[b]
              });
            }
          }
          this.arrcenter.guojixianhuo.sort(this.compare("sort"));
        }
        if (i === "hujin") {
          for (const b in resbody[i].prices) {
            if (resbody[i].prices[b]["display"] == "SHOW") {
              this.arrcenter.hujinPrice.push({
                engname: resbody[i].name,
                sort: resbody[i].prices[b].sort,
                productData: resbody[i].prices[b]
              });
            }
          }
          this.arrcenter.hujinPrice.sort(this.compare("sort"));
        }
        if (i === "originhuangjin") {
          for (const b in resbody[i].prices) {
            if (resbody[i].prices[b]["display"] == "SHOW") {
              this.arrcenter.cihujinPrice.push({
                engname: resbody[i].name,
                sort: resbody[i].prices[b].sort,
                productData: resbody[i].prices[b]
              });
            }
          }
          this.arrcenter.cihujinPrice.sort(this.compare("sort"));
        }
      }


      let res = 0
      this.arrcenter.hujinPrice.forEach((item,i)=>{
        // if (item.engname  == "huangjin9999") {
          res = item.productData.huigou
          this.arrcenter.spotPrice.push({
            engname: item.engname,
            productData: item.productData
          })
        // }
      })
      let res4 = 0
      this.arrcenter.cihujinPrice.forEach((item,i)=>{
        res4 = item.productData.huigou
        this.arrcenter.spotPrice.push({
          engname: item.engname,
          productData: item.productData
        })
      })
      let res1 = 0
      this.arrcenter.baiyin.forEach((item,i)=>{
        if (item.engname  == "baiyin9999") {
          res1 = item.productData.huigou
          this.arrcenter.spotPrice.push({
            engname: '白银',
            productData: item.productData
          })
        }
      })
      let res2 = 0
      this.arrcenter.bojin.forEach((item,i)=>{
        if (item.engname == "bojin9996") {
          res2 = item.productData.huigou
          this.arrcenter.spotPrice.push({
            engname: '铂金',
            productData: item.productData
          })
        }
      })
      let res3 = 0
      this.arrcenter.bajin.forEach((item,i)=>{
        if (item.engname == "bajin9996") {
          res3 = item.productData.huigou

          this.arrcenter.spotPrice.push({
            engname: '钯金',
            productData: item.productData
          })
        }
      })
      this.getNowPrice();
    },
    // 排序
    compare(property) {
      return function(a, b) {
        const value1 = a[property];
        const value2 = b[property];
        return value1 - value2;
      };
    },
    sortNumber(a) {
      return a;
    },
    timebox(date) {
      // const year = new Date().getFullYear();
      // const month = new Date().getMonth() + 1;
      // const day = new Date().getDate();
      // const hour = new Date().getHours();
      // const Min = new Date().getMinutes();
      // const getSeconds = new Date().getSeconds();

      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hour = date.getHours();
      const Min = date.getMinutes();
      const getSeconds = date.getSeconds();
      this.timecenter =
        year +
        "-" +
        (month <= 9 ? "0" + month : month) +
        "-" +
        (day <= 9 ? "0" + day : day) +
        " " +
        (hour <= 9 ? "0" + hour : hour) +
        ":" +
        (Min <= 9 ? "0" + Min : Min) +
        ":" +
        (getSeconds <= 9 ? "0" + getSeconds : getSeconds);
      if (this.checkAuditTime("09:00", "10:15") || this.checkAuditTime("10:30", "11:30") || this.checkAuditTime("13:30", "15:00") || this.checkAuditTime("21:00", "02:30")) {
        this.timeTrade = true
      } else {
        this.timeTrade = false
      }
/*      if (hour == "16" && Min == "20" && getSeconds == "40") {
        console.log(hour + "时" + Min + "分" + getSeconds + "秒");
        this.$eventBus.$emit("onclickLists");
      }*/
    },
    checkAuditTime(startTime, endTime) {
      // 获取当前时间
      const date = new Date()
      // 获取当前时间的年月日
      const dataStr = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} `

      // 获取开始时间、结束时间、现在时间的时间戳
      let startDate = new Date(dataStr + startTime).getTime()
      let endDate = new Date(dataStr + endTime).getTime()
      let nowDate = date.getTime()

      const s = startDate > endDate // 判断开始时间否大于结束时间

      if (s) [startDate, endDate] = [endDate, startDate] // 若开始时间否大于结束时间则交换值

      // 判断现在的时间是否在开始时间和结束时间之间，若s为true则结果取反
      if (nowDate > startDate && nowDate < endDate) {
        return !s
      } else {
        return s
      }
    },
    unzip(b64Data) {
      let strData = atob(b64Data);
      const charData = strData.split("").map(function(x) {
        return x.charCodeAt(0);
      });
      const binData = new Uint8Array(charData);
      const data = pako.inflate(binData);
      strData = String.fromCharCode.apply(null, new Uint16Array(data));
      return decodeURIComponent(escape(strData));
    },
    onConnected() {
      // console.log(this.client)
      const topic = "/price/all";
      // let topic = '/location'
      this.client.subscribe(topic, this.responseCallback, this.onFailed);
    },
    connect() {
      console.log("process.env",process.env)
      this.client = Stomp.over(new WebSocket(process.env.VUE_APP_WS));
      // ---初始化mqtt客户端，并连接mqtt服务
      const headers = {
        login: "username",
        passcode: "password"
      };
      this.client.connect(headers, this.onConnected, this.onFailed);
      this.client.debug = null;
    },
    onFailed(frame) {
      console.log("错误: " + frame);
      this.client = Stomp.over(new WebSocket(process.env.VUE_APP_WS));
      if (!this.client.connected) {
        this.opentrade = false;
        setTimeout(() => {
          // this.connect();
        }, 1000);
      }
    },
    deepObjectMerge(FirstOBJ_, SecondOBJ) {
      // console.log("函数内的数据")
      const FirstOBJ = FirstOBJ_ || this.wsPrice;
      for (const key in SecondOBJ) {
        FirstOBJ[key] =
          FirstOBJ[key] && FirstOBJ[key].toString() === "[object Object]"
            ? this.deepObjectMerge(FirstOBJ[key], SecondOBJ[key])
            : (FirstOBJ[key] = SecondOBJ[key]);
      }
      return FirstOBJ;
    },
    deepMerge(...objs) {
      const than = this;
      const result = Object.create(null);
      objs.forEach(obj => {

        if (obj) {
          Object.keys(obj).forEach(key => {
            const val = obj[key];
            if (than.isPlainObject(val)) {
              // 递归
              if (than.isPlainObject(result[key])) {
                result[key] = than.deepMerge(result[key], val);
              } else {
                result[key] = than.deepMerge(val);
              }
            } else {
              //  数组也要重新赋值  不然依然会引用到其他的
              if (Array.isArray(val)) {
                result[key] = [...val];
              } else {
                result[key] = val;
              }
            }
          });
        }
      });
      return result;
    },
    isPlainObject(val) {
      const toString = Object.prototype.toString;
      return toString.call(val) === "[object Object]";
    },
    extend() {
      const than = this;
      const extended = {};
      let deep = false;
      let i = 0;
      // 判断是否为深拷贝
      if (Object.prototype.toString.call(arguments[0]) === "[object Boolean]") {
        deep = arguments[0];
        i++; //如果为深拷贝则初始的i为1或者为0
      }

      // 将对象属性合并到已存在的对象中
      const merge = function(obj) {
        for (const prop in obj) {
          if (obj.hasOwnProperty(prop)) {
            // 如果属性为对象并且需要深拷贝时则使用函数递归、反之则将当前的属性替换现有的属性
            if (
              deep &&
              Object.prototype.toString.call(obj[prop]) === "[object Object]"
            ) {
              extended[prop] = than.extend(extended[prop], obj[prop]);
            } else {
              extended[prop] = obj[prop];
            }
          }
        }
      };
      // 遍历所有对象属性
      for (; i < arguments.length; i++) {
        merge(arguments[i]);
      }
      return extended;
    },
    responseCallback(frame) {
      const than = this;
      // than.onedata = []
      // console.log(eval("[" + this.unzip(frame.body) + "]"));
      const res_data = eval("[" + this.unzip(frame.body) + "]")[0];
      const parse_res = JSON.parse(JSON.stringify(res_data));
      localStorage.setItem('priceID',parse_res.time)
      if (!this.firstload) {
        than.onedata = than.deepMerge(than.wsPrice, parse_res);
        // --------------------
        than.wsPrice = than.onedata;
        // --------------------
      } else {
        than.firstload = false;
        than.updataarr = parse_res;
        than.wsPrice = parse_res;
      }
      console.debug(parse_res);
      //  是否开盘中
      this.opentrade = parse_res.trade;
      // 保存本地
      localStorage.setItem('opentrade',parse_res.trade)
      if (this.isload) {
        let resbody = "";
        if (JSON.stringify(than.new_tuisong_val_last) == "{}") {
          resbody = than.updataarr;
          this.newlistsort(resbody);
        } else {
        }
      }
      this.isload = true;
      this.havedata = true;
    },
    callPhone() {
      window.location.href = "tel:// 4001-886-722";
    },
    addRouterMap() {
      this.$router.push("/aboutUs");
    },
    noticeClose() {
      this.showNotice = false
      const noticeParams = {
        noticeTime: new Date().getTime(),
        noticeImg: this.noticeImg
      };
      localStorage.setItem("noticeParam1", JSON.stringify(noticeParams));
    },
    quotationShowHandle(){
      if(!this.isWeiXin()){
        return this.$toast('请在公众号中使用此功能'),!1
      }
      this.quotationShow = true;
      const openId = localStorage.getItem("openId");
      if (!openId) {
        const url = encodeURIComponent(process.env.VUE_APP_WEBSITE)
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appId}&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
      } else if (!this.quotationAction || this.quotationAction === 'cancel') {
        const url = encodeURIComponent(process.env.VUE_APP_WEBSITE)
        window.location.href = `https://mp.weixin.qq.com/mp/subscribemsg?action=get_confirm&appid=${this.appId}&scene=${Math.round(Math.random()*10000)}&template_id=${this.templateId}&redirect_url=${url}#wechat_redirect`
        // window.location.href = 'https://mp.weixin.qq.com/mp/subscribemsg?action=get_confirm&appid=wxec9e626df3d166fb&scene=1000&template_id=qDFLozFKoZoVlTUOLBlou1LeQq3aRLAZHUqfpSGr4zk&redirect_url=http%3a%2f%2ftest.shenzhenyht.com#wechat_redirect'
        // window.open(`https://mp.weixin.qq.com/mp/subscribemsg?action=get_confirm&appid=${this.appId}&scene=${Math.round(Math.random()*10000)}&template_id=${this.templateId}&redirect_url=${url}#wechat_redirect`,"_self");
      } else {
        this.quotationForm.openId = openId;
        this.quotationForm.scene = this.$route.query.scene || 1000;
      }
    },
    quotationCommit(){
      api.alertPrice(this.quotationForm).then(res=>{
        console.log(res)
        this.$toast('订阅成功!')
        this.quotationShow = false
        this.quotationAction = ""
        this.categoryShow = false
      })
    },
    quotationFormTypeChange(name){
      this.getNowPrice();
      // this.quotationForm.operator = this.quotationForm.type === 'HUIGOU' ? 'GE' : 'LE'
    },
    isWeiXin () {
      var ua = window.navigator.userAgent.toLowerCase();
      // ua.indexOf('micromessenger')为真-微信端，如果为假，就是其他浏览器
      if (ua.indexOf('micromessenger') > -1) {
        return true; // 是微信端
      } else {
        return false;
      }
    },
    getNowPrice() {
      if (this.quotationForm.key === "bojin9996") {
        this.quotationForm.nowPrice =
          this.quotationForm.type === "HUIGOU"
            ? this.arrcenter.spotPrice[2].productData["huigou"]
            : this.arrcenter.spotPrice[2].productData["xiaoshou"];
      } else if (this.quotationForm.key === "bajin9996") {
        this.quotationForm.nowPrice =
          this.quotationForm.type === "HUIGOU"
            ? this.arrcenter.spotPrice[3].productData["huigou"]
            : this.arrcenter.spotPrice[3].productData["xiaoshou"];
      } else if (this.quotationForm.key === "bojin9999") {
        this.quotationForm.nowPrice =
          this.quotationForm.type === "HUIGOU"
            ? this.arrcenter.spotPrice[1].productData["huigou"]
            : this.arrcenter.spotPrice[1].productData["xiaoshou"];
      } else {
        this.quotationForm.nowPrice =
          this.quotationForm.type === "HUIGOU"
            ? this.arrcenter.spotPrice[0].productData["huigou"]
            : this.arrcenter.spotPrice[0].productData["xiaoshou"];
      }
    },
    confirmCategory(item){
      this.categoryShow = false;
      this.quotationForm.key = item.key;
      this.quotationShow = true;
      this.getNowPrice();
    },
  },
  destroyed() {
    clearTimeout(this.setInterval);
    this.client.disconnect(function() {
      // 断开连接
      // console.log("See you next time!");
    });
  },
  components: {}
};
</script>
<style lang="scss" scoped="true">
::v-deep{
  .van-nav-bar{
    height: 96px;
    .van-nav-bar__content{
      line-height: 96px;
      height: 96px;
      background: #39394d;
      .van-nav-bar__title{
        font-size: 36px;
        color: #f6d0ab;
      }
      .van-icon{
        font-size: 40px;
        color: #fff;
        right: 20px;
      }
    }
  }
  .van-loading__text{
    font-size: 30px;
  }
  .van-list__finished-text{
    font-size: 30px;
  }
  .van-pull-refresh__head{
    font-size:30px !important;
  }
  .van-sticky{
    background: #e6e6ff;
  }
  .van-sticky--fixed{
    top: 96px;
  }
  .van-popup__close-icon--top-left{
    font-size: 36px;
    top: 36px;
    left: 20px;
  }
  .van-radio--horizontal{
    margin-right: 48px;
  }
  .van-radio__icon--checked .van-icon{
    background: #39394D !important;
    color: #f6d0ab;
    border-color: #39394D !important;
  }
  .van-notice-bar {
    margin: 24px 24px 0;
    height: 64px;
    font-size: 24px;
    .van-notice-bar__left-icon {
      font-size: 80px;
      margin-left: -16px;
    }
  }

}
.lastli div {
  font-size: 24px;
}
.greenFont {
  background: #12B34F !important;
}
.redFont {
  background: #FF3333 !important;
}
.spacing {
  height: 16vw;
}
.timeleft {
  font-size: 3.733vw;
  padding-left:30px;
  color: #39394d;
  display: flex;
  justify-content: space-between;
}
.timeright {
  margin-right: 2.667vw;
  text-align: center;
  font-weight: 500;
  color: #00ad8c;
}
.totalwidth {
  width: 92%;
  margin: 40px auto 0;
}
.layer {
  position: relative;
  height: 64vw;
  background-image: url("../assets/img/图层.png");
  background-repeat: no-repeat;
  background-size: 100vw 64vw;
}
.logo {
  width: 270px;
  height: 74px;
  position: absolute;
  left: 4vw;
  top: 6.133vw;
}
.phone {
  width: 168px;
  height: 52px;
  position: absolute;
  left: 4vw;
  top: 20.533vw;
}
.address {
  width: 168px;
  height: 52px;
  position: absolute;
  left: 29.067vw;
  top: 20.533vw;
}
.icon{
  width: 56px;
  height: 56px;
  background: url('~@/assets/images/icon_onlineHome@2x.png') no-repeat;
  background-size: 100%;
}
// .share {
//   width: 32px;
//   height: 32px;
//   position: absolute;
//   left: 92.533vw;
//   top: 8.8vw;
// }
.navleft {
  text-align: center;
}
.navright {
  width: 100%;
  text-align: center;
}
.fillet {
  border-radius: 1.333vw 1.333vw 0 0;
}
.header {
  height: 9.6vw;
  background-color: #FFFFFF;
  color: #39394d;
  font-weight: 600;
  font-size: 4.733vw;
  line-height: 9.6vw;
  display: flex;
  margin-bottom: 8px;
}

// ----------------------------------

.redfont {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f52621 !important;
  font-size: 3.2vw;
  font-weight: 600;
}
.greenfont {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00ad8c !important;
  // ----------------
  font-size: 3.2vw;
  font-weight: 600;
}
.loadingbox {
  margin-top: 250px;
}
.home {
  padding-bottom: 20px;
  background-color: #F1F3F7;
  font-family: PingFang SC-Medium, PingFang SC;
  .borderbox {
    border-bottom: 1px solid #e6e6e6;
  }
  .righbox {
    position: absolute;
    right: 20px;
    top: 12px;
    font-size: 32px;
    top: 35px;
    width: 40px;
    height: 40px;
    img {
      width: 100%;
      max-width: 100%;
    }
  }
  .centerbox {
    display: block;
    color: #fff;
    text-align: center;
    font-size: 36px;
  }
  .back {
    height: 80px;
    background: #FFF0F0;
    line-height: 80px;
  }
  .quote-divider {
    height: 5px;
    background-color: #dcdfe6;
  }
  .whitefont {
    color: #fff !important;
  }
  .fixedbottom {
    border-top: 1px solid #ccc;
    position: fixed;
    bottom: 100px;
    height: 80px;
    left: 0;
    background: #fff;
    line-height: 80px;
    padding: 0 30px;
    width: 100%;
    color: #333232;
    font-size: 28px;
    z-index: 8;
    .open {
      color: #f2aa2e;
    }
  }
  .hrxian {
    height: 20px;
  }
  // .firstul {
  //   border-top: 1px solid #cccccc;
  // }
  .topul {
    text-align: center;
    // padding: 25px 0;
    // font-size: 28px;
    // color: #5c5c5c;
    // ------------------
    width: 100%;
    height: 9.333vw;
    font-size: 4vw;
    color: #919191;
    background-color: #ffffff;
    align-items: center;
    border-bottom: 1px solid #d8d8d8;
  }
  li {
    flex: 2;
  }
  .firstbox {
    // position: relative;
    color: #fba215;
    // flex: 1;
    // background: #fff;
    // word-wrap: break-word;
    // writing-mode: vertical-rl;
    // writing-mode: tb-rl;
    font-size: 28px;
    // display: flex;
    // padding: 0 !important;
    // -webkit-display: flex;
    // flex-direction: column;
    /* align-content: center; */
    /* vertical-align: middle; */
    // border-left: none !important;
    // border-bottom: none !important;
    // justify-content: center;
  }
  .flex_product {
    color: #333333;
    font-weight: 500;
    flex: 2;
    // text-align: left !important;
    // padding-left: 1.667vw !important;
    // width: 210px;
    // border-left: 1px solid #cccccc;
  }
  .productitems {
    position: relative;
    background-color: #FFFFFF;
    margin-bottom: 30px;
    padding: 20px 20px 30px;
    box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.05);
    border-radius: 4px 4px 4px 4px;
  }
  .productitems .ulcenter {
    &:nth-child(even) {
      //background: #F1F3F7;
    }
  }
  .productlist {
    li {
      // border-bottom: 1px solid #cccccc;
      // border-left: 1px solid #cccccc;
      text-align: center;
      padding: 28px 0;
      font-size: 4vw;
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        color: red;
      }
      border-bottom: 1px solid #d8d8d8;
    }
    .lastli {
      padding: 10px 0;
    }
  }
  .lastul {
    // border-bottom: 1px solid #cccccc;
    li {
      border-bottom: none;
    }
  }
}
.lastli {
  display: grid !important;
  // padding: 30px 0 0 0!important;
}
.oddli {
  z-index: 3 !important;
  position: absolute !important;
  left: 2% !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
}
.testClor {
  color: #000;
}
.head{
  height: 100px;
  width: 100%;
  color: #fff;
  text-align: center;
  line-height: 100px;
  font-size: 32px;
  background-color: #FF2D2E;
}
.headerBg{

  width: 48px;
  height:48px;
  margin: auto 0;
  margin-right: 16px;
}
.priceBg {
  background: url("~@/assets/images/icon_spot_goods_black@2x.png") no-repeat;
  background-size: 100% 100%;
}
.homeQuotationBg {
  background: url("~@/assets/images/icon_domestic_black@2x.png") no-repeat;
  background-size: 100% 100%;
}
.internationalBg {
  background: url("~@/assets/images/icon_world_black@2x.png") no-repeat;
  background-size: 100% 100%;
}
.beian{
  text-align: center;
  font-size: 24px;
  color: #a5a5a5;
}
.quotationTitle{
  height: 100px;
  text-align: center;
  line-height: 100px;
  font-size: 28px;
  font-weight: 600;
}
.quotationForm{
  padding: 0 20px;
  font-size: 24px;
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  .quotationFormItem{
    border-top: 1px solid #cccccc;
    padding: 20px 0;
  }
  .quotationFormItem:nth-child(1){
    border-top: none;
    padding: 20px 0;
  }
  .titleinfo {
    width: 200px;
    margin-right: 50px;
  }
}
::v-deep .el-input__inner {
  border: 0;
  font-size: 24px;
  height: auto;
  line-height: normal;
}
.quotationDescribe{
  padding: 20px 20px 40px;
  color: #cccccc;
  font-size: 20px;
}
.quotationBtn {
  display: block;
  margin: 20px auto;
  background: #39394d;
  width: 660px;
  height: 80px;
  color: #f6d0ab;
  font-size: 24px;
  border-radius: 32px;
}
.flex_between{
  display: flex;
  justify-content: space-between;
  width: 480px;
}
</style>
